export const LogoNoText = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.6em"
    height="1.6em"
    viewBox="0 0 65 75.11"
    style={{verticalAlign: "middle"}}
  >
    <path
      style={{
        fill: "#d9dcd9",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M37.328 26.57v12.125l10.5 6.063 10.5-6.063V26.57l-10.5-6.062-10.5 6.062"
      transform="translate(0 -18)"
    />
    <path
      style={{
        fill: "#bfcfcf",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13.328 26.57v12.125l10.5 6.063 10.5-6.063V26.57l-10.5-6.062-10.5 6.062"
      transform="translate(0 -18)"
    />
    <path
      style={{
        fill: "#94a6b0",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M1.328 47.57v12.125l10.5 6.063 10.5-6.063V47.57l-10.5-6.062-10.5 6.062"
      transform="translate(0 -18)"
    />
    <path
      style={{
        fill: "#f06157",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13.328 68.57v12.125l10.5 6.063 10.5-6.063V68.57l-10.5-6.062-10.5 6.062"
      transform="translate(0 -18)"
    />
    <path
      style={{
        fill: "#d9dcd9",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M37.328 68.57v12.125l10.5 6.063 10.5-6.063V68.57l-10.5-6.062-10.5 6.062"
      transform="translate(0 -18)"
    />
  </svg>
)

export default LogoNoText;
