import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Box from "@mui/material/Box";
import { Button, Grid, Typography } from "@mui/material";
import Logo from "../assets/Logo";
import HMIs from "./HMIs";
import LoginIcon from '@mui/icons-material/Login';

interface IProps {
  userEmail: string;
  isLoading: boolean;
}

const Start: React.FC<IProps> = ({ userEmail }) => {

  const { loginWithRedirect, isAuthenticated } = useAuth0();

  if (isAuthenticated) {
  return <HMIs userEmail={userEmail} />
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3, paddingTop: 10 }}>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item sm={12} sx={{ textAlign: "center" }}>
          <Logo height={120} width={340} />
            <Typography sx={{ flex: 1 }} variant="h3" fontWeight={500}>
              i-BRUSH Portal
            </Typography>

            <Typography
              sx={{ flex: 1, marginTop: 2, color: "#505256" }}
              variant="h5"
              fontWeight={400}
            >
              Where you can monitor your i-BRUSH 2.0 systems
            </Typography>

            <Button
            variant="contained"
            onClick={() => loginWithRedirect()}
            disableElevation={true}
            size="medium"
            sx={{
              color: "#FFF",
              p: 2,
              marginTop: 5, }}
            startIcon={<LoginIcon />}
          >
           Log in here
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Start;
