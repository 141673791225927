import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import logo from "../assets/carbex_logo.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { capitalizeFirstLetter } from "../common/functions";

const theme = createTheme({
  palette: {
    primary: {
      main: grey[100],
    },
    secondary: {
      main: grey[100],
    },
  },
});

interface IProps {
  userRoles: string[];
  userNickname: string;
  onLogout: () => void;
}

const ResponsiveAppBar: React.FC<IProps> = ({
  userRoles,
  userNickname,
  onLogout,
}) => {
  const { loginWithRedirect } = useAuth0();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  let location = useLocation();
  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    onLogout();
  };

  return (
    <AppBar
      position="static"
      elevation={0}
      style={{ backgroundColor: "#004d64" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <ThemeProvider theme={theme}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            <img
              src={logo}
              className="App-logo"
              alt="Carbex"
              style={{ height: 27 }}
            />
          </Typography>

          {userRoles.length > 0 && (
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    onClick={() => navigate("hmis")}
                  >
                    Start
                  </Typography>
                </MenuItem>
                {userRoles.includes("admin") && (
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography
                      textAlign="center"
                      onClick={() => navigate("admin/clients")}
                    >
                      Admin
                    </Typography>
                  </MenuItem>
                )}
              </Menu>
            </Box>
          )}
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            <img
              src={logo}
              className="App-logo"
              alt="Carbex"
              style={{ height: 25 }}
            />
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", marginLeft: 40 },
            }}
          >
            <Stack direction="row" spacing={2}>
                {userRoles.length > 0 && (
                  <Button
                    variant={
                      location.pathname.includes("hmis")
                        ? "contained"
                        : "text"
                    }
                    onClick={() => navigate("hmis")}
                    color="primary"
                    disableElevation={true}
                    sx={{ textTransform: "none" }}
                    startIcon={<HomeIcon />}
                  >
                    Start
                  </Button>
                )}

                {userRoles.includes("admin") && (
                  <Button
                    variant={
                      location.pathname.includes("admin") ? "contained" : "text"
                    }
                    onClick={() => navigate("/admin/clients")}
                    color="primary"
                    disableElevation={true}
                    sx={{ textTransform: "none" }}
                    startIcon={<AdminPanelSettingsIcon />}
                  >
                    Admin
                  </Button>
                )}
             
            </Stack>
          </Box>

          {userRoles.length > 0 && (
            <Box sx={{ flexGrow: 0 }}>
              <Button
                variant={
                  location.pathname.includes("profile") ? "contained" : "text"
                }
                onClick={() => navigate("profile")}
                color="primary"
                disableElevation={true}
                sx={{ textTransform: "none" }}
                startIcon={<AccountCircleIcon />}
              >
               {capitalizeFirstLetter(userNickname)}
              </Button>

            </Box>
          )}
          {userRoles.length === 0 && (
            <Link
              sx={{ color: "#FFF" }}
              component="button"
              variant="body1"
              underline="none"
              onClick={() => loginWithRedirect()}
            >
              Login
            </Link>
          )}
           </ThemeProvider>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
