import { useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import * as apiClient from "../services/apiClient";
import Button from "@mui/material/Button";
import LockResetIcon from '@mui/icons-material/LockReset';
import LogoutIcon from '@mui/icons-material/Logout';

const Profile = () => {
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const [resetLinkSent, setResetLinkSent] = useState<boolean>(false);

  const { email } = user!;

  const handleResetPwd = async () => {
    try {
      if (typeof email !== "undefined") {
        const token = await getAccessTokenSilently();
        let res = await apiClient.resetPwd(token, email);
        if (res === true) {
          setResetLinkSent(true);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      <div
        className="row align-items-center profile-header"
        style={{ textAlign: "center", marginTop: 40 }}
      >
        <div className="col-md-2 mb-3">
          <AccountCircleIcon style={{ fontSize: "6em" }} />
        </div>
        <div className="col-md text-center text-md-left">
          <h2>{email}</h2>


          {resetLinkSent && (
            <h3 style={{ color: "#004d64" }}>
              Thank you! We've just sent you an email to reset your password
            </h3>
          )}
  
          <Button
            disabled={resetLinkSent}
            variant="contained"
            onClick={handleResetPwd}
            disableElevation={true}
            size="medium"
            sx={{ textTransform: "none", mr: 1 }}
            startIcon={<LockResetIcon />}
          >
            Reset password
          </Button>

          <Button
            variant="contained"
            onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
            disableElevation={true}
            size="medium"
            sx={{ textTransform: "none" }}
            startIcon={<LogoutIcon />}
          >
            Log out
          </Button>
        </div>
      </div>
    </div>
  );
};

//export default Profile;

export default withAuthenticationRequired(Profile, {
  onRedirecting: () => <span>Loading</span>,
});
