import * as constants from '../constants/index';
import { IClient } from '../models/Client';
import { IUser } from '../models/User';
import { IHmi, IHmiAdress } from '../models/Hmi';

export const isAdmin = async (token: string) => {
  try {
    const resp = await fetch(
      `${constants.apiBaseUrl}is-admin`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const res = await resp;
    return res.ok;
  } catch (error) {
    return error;
  }
};

export const resetPwd = async (token: string, email: string) => {
  try {
    const resp = await fetch(
      `${constants.apiBaseUrl}reset-password/${email}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const res = await resp;
    return res.ok;
  } catch (error) {
    return error;
  }
};

export const createClient = async (token: string, client: IClient) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}client`, {
      method: 'POST',
      headers: {
        "Accept": 'application/json',
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(client)
    });
    const res = await resp.json();
    return res;
  } catch (error) {
      console.log("error");
  }
};

export const updateClient = async (token: string, client: IClient) => {
  try {

    const resp = await fetch(`${constants.apiBaseUrl}client/${client.id}`, {
      method: 'PUT',
      headers: {
        "Accept": 'application/json',
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(client)
    });
    const res = await resp.json();
    return res;
  } catch (error) {
      console.log("error");
  }
};

export const updateHmi = async (token: string, hmi: IHmi) => {
  try {

    const resp = await fetch(`${constants.apiBaseUrl}hmi/${hmi.id}`, {
      method: 'PUT',
      headers: {
        "Accept": 'application/json',
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(hmi)
    });
    const res = await resp.json();
    return res;
  } catch (error) {
      console.log("error");
  }
};

export const createHmi = async (token: string, hmi: IHmi) => {
  try {
    const resp = await fetch(`${constants.apiBaseUrl}hmi`, {
      method: 'POST',
      headers: {
        "Accept": 'application/json',
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(hmi)
    });
    const res = await resp.json();
    return res;
  } catch (error) {
      console.log("error");
  }
};

export const getUsers = async (token: string) => {
  try {
    const resp = await fetch(
      `${constants.apiBaseUrl}users`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const res = await resp.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const getHmisByClient = async (token: string, clientId: string) => {
  try {
    const resp = await fetch(
      `${constants.apiBaseUrl}hmis/${clientId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const res = await resp.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const getHmisByUser = async (token: string, email: string) => {
  try {
    const resp = await fetch(
      `${constants.apiBaseUrl}hmis-by-user/${email}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    console.log(resp);
    const res = await resp.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const getClient = async (token: string, clientId: string) => {
  try {
    const resp = await fetch(
      `${constants.apiBaseUrl}client/${clientId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const res = await resp.json();
    return res;
  } catch (error) {
    throw error;
  }
};

export const getClients = async (token: string) => {
    try {
      const resp = await fetch(
        `${constants.apiBaseUrl}clients`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const res = await resp.json();
      return res;
    } catch (error) {
        console.log("error");
    }
  };

  export const deleteClient = async (token: string, id: number) => {
    try {
      const resp = await fetch(`${constants.apiBaseUrl}client/${id}`, {
        method: 'DELETE',
        headers: {
          "Accept": 'application/json',
          "Content-Type": 'application/json',
          "Authorization": `Bearer ${token}`
        },
      });
      const res = await resp.json();
      return res;
    } catch (error) {
        console.log("error", error);
    }
  };

  export const deleteUser = async (token: string, email: string) => {
    try {
      const resp = await fetch(`${constants.apiBaseUrl}user/${email}`, {
        method: 'DELETE',
        headers: {
          "Accept": 'application/json',
          "Content-Type": 'application/json',
          "Authorization": `Bearer ${token}`
        },
      });
      const res = await resp.json();
      return res;
    } catch (error) {
        console.log("error");
    }
  };

  export const deleteHmi = async (token: string, id: number) => {
    try {
      const resp = await fetch(`${constants.apiBaseUrl}hmi/${id}`, {
        method: 'DELETE',
        headers: {
          "Accept": 'application/json',
          "Content-Type": 'application/json',
          "Authorization": `Bearer ${token}`
        },
      });
      const res = await resp.json();
      return res;
    } catch (error) {
        console.log("error");
    }
  };

  
  export const addUserToClient = async (token: string, user: IUser) => {
    try {
  
      const resp = await fetch(`${constants.apiBaseUrl}add-user-to-client`, {
        method: 'POST',
        headers: {
          "Accept": 'application/json',
          "Content-Type": 'application/json',
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(user)
      });
      const res = await resp.json();
      return res;
    } catch (error) {
        console.log("error");
    }
  };

  export const getAuth0Users = async (token: string) => {
    try {
      const resp = await fetch(
        `${constants.apiBaseUrl}auth0Users`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const res = await resp.json();
      return res;
    } catch (error) {
      throw error;
    }
  };