import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 460 460"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <path fill="rgba(0, 0, 0, 0.26)" d="M196.664 284.046h-12.638v19.601h13.282c1.548 0 2.986-.709 4.32-2.127 1.332-1.419 1.999-3.976 1.999-7.673 0-3.782-.774-6.361-2.321-7.738-1.547-1.374-3.094-2.063-4.642-2.063zM101.623 283.917c-13.479 0-20.891 10.865-20.891 24.114 0 13.276 7.651 24.115 21.02 24.115 13.567 0 20.892-10.954 20.892-24.373 0-14.524-8.749-23.856-21.021-23.856z" />
    <path fill="rgba(0, 0, 0, 0.26)" d="M426.5 193.445h-37.325L244.143 48.413a20.005 20.005 0 0 0-28.285 0L70.826 193.445H33.5c-18.501 0-33.5 14.998-33.5 33.5v157c0 18.501 14.998 33.5 33.5 33.5h393c18.501 0 33.5-14.999 33.5-33.5v-157c0-18.502-14.999-33.5-33.5-33.5zM230 90.839l102.605 102.606h-205.21L230 90.839zM101.494 354.456c-26.824 0-46.295-22.292-46.295-46.682 0-24.589 19.982-46.166 46.811-46.166 27.379 0 46.167 22.746 46.167 46.682 0 24.279-19.884 46.166-46.683 46.166zm97.491-28.886h-14.959v28.112H158.88v-91.559h39.461c17.911 0 30.82 16.145 30.82 31.724 0 15.879-11.74 31.723-30.176 31.723zm105.485 28.112h-65.252v-91.559h64.091v21.923h-38.944v12.896h33.271v20.375h-33.271v14.442h40.105v21.923zm73.244 0-36.365-45.006v45.006h-25.147v-91.559h19.602l36.752 46.553v-46.553h25.146v91.559h-19.988z" />
  </svg>
)

export default SvgComponent
