import React, { useEffect, useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {
  Alert,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import * as apiClient from "../services/apiClient";
import { IHmi, IHmiAdress } from "../models/Hmi";
import LogoNoText from "../assets/LogoNoText";
import EditIcon from "@mui/icons-material/Edit";
import Link from "@mui/material/Link";
import EditHmiDetailsDialog from "../components/EditHmiDetailsDialog";
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Open from "../assets/Open";
import CircleIcon from '@mui/icons-material/Circle';
import axios from 'axios';

interface IProps {
  userEmail: string;
}

const HMIs: React.FC<IProps> = ({ userEmail }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [hmis, setHmis] = useState<Array<IHmi>>([]);
  const [editHmiDetailsDialogIsOpen, setEditHmiDetailsDialogIsOpen] =
    useState<boolean>(false);
  const [hmiToEdit, setHmiToEdit] = useState<IHmi | null>(null);
  const [startFetchStatuses, setStartFetchStatuses] = useState<boolean>(true);

  const getHMIs = async () => {
    try {
      if (userEmail !== "") {
        const token = await getAccessTokenSilently();
        const hmis = await apiClient.getHmisByUser(token, userEmail);
        hmis.forEach((hmi: IHmi) => {
          hmi.status = "loading";
        });

        setHmis(hmis);
        setStartFetchStatuses(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {

    const fetchHmis = async () => {
      await getHMIs();
    }
    fetchHmis();
   
  }, [userEmail]);


  const checkHmiStatuses = async () => {
    const token = await getAccessTokenSilently();
    let _hmis = [...hmis];

    for (const hmi of _hmis) {

      let ip = hmi.ip;
      
      let adressWithoutPort =  ip.substring(0, ip.lastIndexOf(':'));
      hmi.deviceAdress = adressWithoutPort + ":3000/d/_b7ui0QGk/loggerdata?orgId=1&refresh=1m&from=time-24h&to=time";

      try {
      const res = await axios(hmi.ip, {
        method: "GET",
        timeout: 2000
      });
      if(typeof res.data !== "undefined") {
        hmi.status = "ok";
      } else {
        hmi.status = "noAccess";
      }
    } catch (err: any) {
      if (axios.isAxiosError(err))  {
        if(err.message === "Network Error") {
          hmi.status = "ok";
        } else {
          hmi.status = "noAccess";
        }
      } else {
        hmi.status = "noAccess";
      }
    }
    }
    setHmis(_hmis);
   // setTimeout(checkHmiStatuses, 15000);
  };

  useEffect(() => {
    if(startFetchStatuses && hmis.length > 0) {
      checkHmiStatuses();
      setStartFetchStatuses(false);
    }
  }, [hmis.length]);

  const handleSaveHmiDetails = async () => {
    try {
      if (hmiToEdit !== null) {
        const token = await getAccessTokenSilently();
        await apiClient.updateHmi(token, hmiToEdit);
        await getHMIs();
        handleCloseHmiDetailsDialog();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleChangeName = (name: string) => {
    if (hmiToEdit !== null) {
      setHmiToEdit({ ...hmiToEdit, name: name });
    }
  };

  const handleChangeLocation = (location: string) => {
    if (hmiToEdit !== null) {
      setHmiToEdit({ ...hmiToEdit, location: location });
    }
  };

  const handleOpenHmiDetailsDialog = (hmi: IHmi) => {
    setHmiToEdit(hmi);
    setEditHmiDetailsDialogIsOpen(true);
  };

  const handleCloseHmiDetailsDialog = () => {
    setEditHmiDetailsDialogIsOpen(false);
  };

  interface IconProps {
    status?: string;
  }

 const HmiStatusIcon: React.FC<IconProps> = ({ status }) => {
    switch(status) {
      case "ok":
        return <span><CircleIcon fontSize="small" sx={{color: "#1aa260", verticalAlign: "text-bottom"}}/> (OK)</span>
      case "noAccess":
        return <span><CircleIcon fontSize="small" sx={{color: "#DE5246", verticalAlign: "text-bottom"}}/> (Offline)</span>
      default:
        return <span>Loading status...</span>
    }
  }

  return (
    <div style={{ backgroundColor: "#F5F5F5", paddingTop: 14 }}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={11} lg={8}>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              style={{ color: "grey" }}
            >
              Available systems:
            </Typography>
            <Alert severity="info">
            Note that you need to have the software “Tosibox Key” running on your device to access your i-BRUSH systems.
            </Alert>
            {hmis.length > 0 &&
              hmis.map((hmi: IHmi) => {
                return (
                  <Card
                    key={hmi.id}
                    sx={{
                      minWidth: 100,
                      marginTop: 3,
                      paddingTop: 1,
                      position: "relative",
                    }}
                  >
                    <CardContent>
                      <Typography variant="h5" component="div">
                        <LogoNoText />
                        <span>
                          &nbsp;&nbsp;&nbsp;
                          <Link
                            href={`${hmi.ip}`}
                            target="_blank"
                            underline="hover"
                          >
                            {hmi.name}
                          </Link>
                        </span>
                      </Typography>
                      <IconButton
                        color="primary"
                        aria-label="Edit"
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          marginTop: 2,
                          marginRight: 2,
                        }}
                        onClick={() => handleOpenHmiDetailsDialog(hmi)}
                      >
                        <EditIcon />
                      </IconButton>
                      <Typography sx={{ marginTop: 2 }}>

                      <span style={{ fontWeight: "bold" }}>
                          Status:&nbsp;
                        </span>
                        <HmiStatusIcon status={hmi.status}/>
                     
                        <br/>

                        <span style={{ fontWeight: "bold" }}>
                          Location:&nbsp;
                        </span>
                        {hmi.location}
                       
                      </Typography>
                      <br />
                      <Link
                        href={`${hmi.ip}`}
                        target="_blank"
                        underline="hover"
                      >
                        {`Go to ${hmi.name}`}
                      </Link>
                      
                      <Link
                        sx={{position: "absolute", right: 0, marginRight: 2}}
                        href={`${hmi.deviceAdress}`}
                        target="_blank"
                        underline="hover"
                      >
                        {`Go directly to database trends`}
                      </Link>
                    </CardContent>
                  </Card>
                );
              })}
          </Grid>
        </Grid>
        <Grid
          sx={{mt: 3}}
          container
          spacing={5}
          direction="row"
          justifyContent="center"
        >
           <Grid item xs={12} sx={{ textAlign: "center", mb: 3 }}>
           <span style={{fontWeight: "bold", fontSize: "2em"}}>SUPPORT</span>
           </Grid>
          <Grid item sx={{ textAlign: "center" }}><MailIcon color="disabled" sx={{fontSize: 46}}/>
          <br/>
          <br/>
          <span style={{fontWeight: "bold"}}>PHONE / MAIL</span>
          <br/>
          <br/>
          +46(0) 143 294 40<br/>
          <a href = "mailto: i-brush.support@carbex.se">i-brush.support@carbex.se</a>
          </Grid>
          <Grid item sx={{ textAlign: "center" }}><LocationOnIcon color="disabled" sx={{fontSize: 46}}/>
          <br/>
          <br/>
          <span style={{fontWeight: "bold"}}>HEADQUARTERS</span>
          <br/>
          <br/>
          Kvarnbacksvägen 12<br/>
          SE-592 41 Vadstena<br/>
          Sweden
          </Grid>
          <Grid item sx={{ textAlign: "center" }}><Open color="disabled" style={{fontSize: 46}}/>
          <br/>
          <br/>
          <span style={{fontWeight: "bold"}}>OPENING HOURS</span>
          <br/>
          <br/>
          Monday - Thursday<br/>
          07:30 - 16:30<br/> (7:30 am - 4:30 pm)<br/>
          Friday<br/>
          07:30 - 14:00<br/> (7:30 am - 2:00 pm)
          </Grid>
        </Grid>

        <EditHmiDetailsDialog
          open={editHmiDetailsDialogIsOpen}
          onClose={handleCloseHmiDetailsDialog}
          hmi={hmiToEdit}
          onSave={handleSaveHmiDetails}
          onChangeName={handleChangeName}
          onChangeLocation={handleChangeLocation}
        />
    </div>
  );
};

export default withAuthenticationRequired(HMIs, {
  onRedirecting: () => <span>Loading</span>,
});
