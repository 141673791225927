import React, { useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import ListClientsTable from '../../components/ListClientsTable';
import * as apiClient from '../../services/apiClient';
import Box from '@mui/material/Box';
import { IClient, Data } from '../../models/Client';
import { useNavigate } from "react-router-dom";
import * as routes from "../../constants/routes";
import Button from '@mui/material/Button';
import { DialogActions, Grid, Paper, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

function createData(
  open: any,
  id: number,
  name: string,
  contact: string,
): Data {
  return { open, id, name, contact };
}

const ListClients: React.FC = () => {

  const { getAccessTokenSilently } = useAuth0();
  const [rows, setRows] = useState<Array<Data>>([]);
  const [createClientIsOpen, setCreateClientIsOpen] = useState<boolean>(false);
  const [addClientDialogIsOpen, setAddClientDialogIsOpen] = useState<boolean>(false);
  const [clientName, setClientName] = React.useState("");
  const [contact, setContact] = React.useState("");

  const navigate = useNavigate();

  interface Props {
    id: number;
  }

  const OpenButton: React.FC<Props> = ({ id }) => {
    return <Button
      variant="contained"
      disableElevation
      size="small"
      onClick={() => navigate(`${routes.ADMIN_CLIENT}/${id}`)}
      sx={{ my: 0, color: '#FFF', textTransform: 'none' }}
    >
      Open
    </Button>;
  }

  const handleCreateClient = async () => {

    try {
      const token = await getAccessTokenSilently();
      let client: IClient = { id: 1, name: clientName, contact: contact };
      const res: IClient = await apiClient.createClient(token, client);
      await getClients();
      handleClose();
      if (typeof res === "undefined") {
        //  setError(true);
      }
    } catch (error) {
      // setError(true);
      // console.log("errorsss", error);
    }
  };

  const handleClose = () => {
    // setError(false);
    setCreateClientIsOpen(false);
    setClientName("");
    setContact("");

  };

  const getClients = async () => {
    const token = await getAccessTokenSilently();
    const clients: IClient[] = await apiClient.getClients(token);

    const rows = clients.map((client: IClient) => {
      return createData(<OpenButton id={client.id} />, client.id, client.name, client.contact);
    });
    setRows(rows);
  }

  useEffect(() => {
    getClients();
  }, []);

  return (
    <div style={{backgroundColor: "#F5F5F5" }}>
    <Box sx={{ flexGrow: 1, marginTop: 0, p: 3 }}>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center">
        <Grid item xs={11} lg={7}>
          <Paper sx={{ mb: 2, p: 2 }}>
            {createClientIsOpen === false && <ListClientsTable rows={rows} onCreateClient={() => setCreateClientIsOpen(true)} />}
            {createClientIsOpen && <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { marginTop: 2 },
              }}
              noValidate
              autoComplete="off"
            >
              <Typography variant="h4" gutterBottom component="div">
                Create client
              </Typography>

              <TextField
                required
                fullWidth={true}
                id="outlined"
                label="Client name"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
              />

              <TextField
                required
                fullWidth={true}
                id="outlined"
                label="Contact"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
              />

              <DialogActions>

                <Button variant="outlined" onClick={handleClose} disableElevation={true} size="medium" sx={{ textTransform: "none" }}>
                  Cancel
                </Button>

                <Button variant="contained" onClick={handleCreateClient} disableElevation={true} size="medium" sx={{ textTransform: "none" }}>
                  + Create client
                </Button>
              </DialogActions>
            </Box>}
          </Paper>
        </Grid>
      </Grid>
    </Box>
    </div>
  );
};
//export default Clients;
export default withAuthenticationRequired(ListClients, {
  onRedirecting: () => <span>Loading</span>,
});