import React from 'react';
import { useNavigate  } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

interface IProps {
    children: React.ReactNode;
}

const Auth0ProviderWithHistory: React.FC<IProps> = ({ children }) => {

  const navigate  = useNavigate();
  
    const onRedirectCallback = (appState: any) => {
      navigate(appState?.returnTo || window.location.pathname);
    };
  
    return (
      <Auth0Provider
        domain={"carbex.eu.auth0.com"}
        clientId={"FTB8EosqIVR7h0B9h1m3rGmR67Mcr8Ha"}
        authorizationParams={{
          redirect_uri: window.location.origin,
        }}
        onRedirectCallback={onRedirectCallback}
      >
        {children}
      </Auth0Provider>
    );
  };
  
  export default Auth0ProviderWithHistory;