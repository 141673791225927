import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import { HashRouter as Router } from 'react-router-dom';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';

ReactDOM.render(
  <React.StrictMode>
    <Router>
    <Auth0ProviderWithHistory>
      <Auth0Provider
        useRefreshTokens={true}
        domain="carbex.eu.auth0.com"
        clientId="FTB8EosqIVR7h0B9h1m3rGmR67Mcr8Ha"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://carbex-hmi-portal-api.azurewebsites.net"
        }}
       //redirectUri={"http://localhost:3000"}
        //audience="https://dev-1vqbztsc.us.auth0.com/api/v2/"
        //audience="https://carbex-ibrushhmi.com/api"
        cacheLocation="localstorage"
      >
        <App />
      </Auth0Provider>
      </Auth0ProviderWithHistory>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
