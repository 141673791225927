import React, { useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import * as apiClient from '../../services/apiClient';
import Box from '@mui/material/Box';
import { IClient } from '../../models/Client';
import { IUser, IUserRow } from '../../models/User';
import { useNavigate, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { DialogActions, Grid, Typography } from '@mui/material';
import ListUsersTable from '../../components/ListUsersTable';
import { ISelectOption } from '../../models/SelectOption';
import Select from 'react-select';
import ListHMIsTable from '../../components/ListHMIsTable';
import { IHmi, IHmiRow } from '../../models/Hmi';

function createUserData(
  open: any,
  id: number,
  email: string,
): IUserRow {
  return { open, id, email };
}

function createHmiData(
  open: any,
  id: number,
  ip: string,
  name: string,
  location: string,
  sysName: string
): IHmiRow {
  return { open, id, ip, name, location, sysName };
}

interface IProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<IProps> = ({ children, index, value, ...other }) => {

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
interface IPropsEditClient {
  userEmail: string;
}


const EditClient: React.FC<IPropsEditClient> = ({ userEmail }) => {

  const [client, setClient] = useState<IClient | null>(null);

  const [tabValue, setTabValue] = useState(0);
  const [userRows, setUserRows] = useState<Array<IUserRow>>([]);
  const [hmiRows, setHmiRows] = useState<Array<IHmiRow>>([]);
  const [userInEdit, setUserInEdit] = useState<IUser | null>(null);
  const [hmiInEdit, setHmiInEdit] = useState<IHmi | null>(null);
  const [options, setOptions] = useState<Array<ISelectOption>>([]);
  const [selectedEmail, setSelectedEmail] = React.useState<string>("");
  const [route, setRoute] = React.useState<string>("");
  const [clientIsSaved, setClientIsSaved] = React.useState<boolean>(true);
  const [hmiIsSaved, setHmiIsSaved] = React.useState<boolean>(true);

  const navigate = useNavigate();
  let { clientId } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    handleSetRoute(newValue);
  };

  const handleTabClick = (index: number) => {
    if (tabValue === index) {
      handleSetRoute(index);
    }
  };

  const handleSetRoute = (tabIndex: number) => {

    switch (tabIndex) {
      case 1:
        setRoute("listUsers");
        break;
      case 2:
        setRoute("listHMIs");
        break;
      default:
        setRoute("");
    }
  }

  const onSelectChange = (option: any) => {
    setSelectedEmail(option.value);
  }

  interface IEditUserButtonProps {
    user: IUser;
  }

  const EditUserButton: React.FC<IEditUserButtonProps> = ({ user }) => {
    return <Button
      variant="contained"
      disableElevation
      size="small"
      onClick={() => { setUserInEdit(user); setRoute("editUser") }}
      sx={{ my: 0, color: '#FFF', textTransform: 'none' }}
    >
      Edit
    </Button>;
  }

  interface IEditHmiButtonProps {
    hmi: IHmi;
  }

  const EditHmiButton: React.FC<IEditHmiButtonProps> = ({ hmi }) => {
    return <Button
      variant="contained"
      disableElevation
      size="small"
      onClick={() => { setHmiInEdit(hmi); setRoute("editHmi") }}
      sx={{ my: 0, color: '#FFF', textTransform: 'none' }}
    >
      Edit
    </Button>;
  }

  const getInitData = async () => {
    try {
      setUserInEdit(null);
      setRoute("listUsers");

      if (typeof clientId !== "undefined") {
        const token = await getAccessTokenSilently();
        const client: IClient = await apiClient.getClient(token, clientId);
        if (client !== null) {
          setClient(client);

          const users = await apiClient.getUsers(token);

          await handleRefreshHmis(token, clientId);

          let usersEmailKeys = users.map((x: IUser) => x.email);

          //Administratören kan lägga till sig själv på flera kunder.
          usersEmailKeys = usersEmailKeys.filter((e: string) => e !== userEmail);

          const auth0Users: string[] = await apiClient.getAuth0Users(token);
       
          const filteredAuth0Users = auth0Users.filter((e: string) => !usersEmailKeys.includes(e));
       
          const options = filteredAuth0Users.map((email: string) => {
            return { value: email, label: email };
          });

          setOptions(options);

          if (typeof clientId !== "undefined") {
            let parsedClientId = parseInt(clientId);
            if (!isNaN(parsedClientId)) {
              //Skapar rader för användare.
              const userRows = users.map((user: IUser) => {
                if (user.clientId === parsedClientId) {
                  return createUserData(<EditUserButton user={user} />, user.id, user.email);
                }
              }).filter(Boolean);
              setUserRows(userRows);
            }
          }
        }
      }
    } catch (error) {
      console.log("errro", error);
    }
  }

  useEffect(() => {
    getInitData();
  }, []);


  const handleRefreshHmis = async (token: string, clientId: string) => {
    try {
      const hmis = await apiClient.getHmisByClient(token, clientId);
      const hmiRows = hmis.map((hmi: IHmi) => {
        return createHmiData(<EditHmiButton hmi={hmi} />, hmi.id, hmi.ip, hmi.name, hmi.location, hmi.sysName);
      }).filter(Boolean);
      setHmiRows(hmiRows);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleUpdateHmi = async () => {
    try {
      if (hmiInEdit !== null) {
        const token = await getAccessTokenSilently();
        await apiClient.updateHmi(token, hmiInEdit);
        await handleRefreshHmis(token, clientId!);
        setHmiIsSaved(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCreateHmi = async () => {
    try {
      if (hmiInEdit !== null) {
        const token = await getAccessTokenSilently();
        await apiClient.createHmi(token, hmiInEdit);
        await handleRefreshHmis(token, clientId!);
        setRoute("listHMIs");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleUpdateClient = async () => {
    try {
      if (client !== null) {
        const token = await getAccessTokenSilently();
        const res: IClient = await apiClient.updateClient(token, client);
        setClientIsSaved(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleDeleteClient = async () => {
    if (client !== null) {
    const token = await getAccessTokenSilently();
    await apiClient.deleteClient(token, client.id);
    await getInitData();
    navigate("/admin/clients");
    }
  }

  const handleDeleteUser = async () => {
    const token = await getAccessTokenSilently();
    const res: any = await apiClient.deleteUser(token, userInEdit!.email);
    await getInitData();
  }

  const handleDeleteHMI = async () => {
    const token = await getAccessTokenSilently();
    const res: any = await apiClient.deleteHmi(token, hmiInEdit!.id);
    await handleRefreshHmis(token, clientId!);
    setRoute("listHMIs");
  }

  const handleCreateHMIDialog = async () => {
    if (typeof clientId !== "undefined") {
      let parsedClientId = parseInt(clientId);
      setHmiInEdit({
        id: 0,
        ip: "",
        name: "",
        location: "",
        sysName: "",
        clientId: parsedClientId,
      });
      setRoute("createHmi");
    }
  }

  const handleAddUser = async () => {

    try {
      if (typeof clientId !== "undefined") {
        let parsedClientId = parseInt(clientId);
        if (!isNaN(parsedClientId)) {
          const token = await getAccessTokenSilently();
          let user: IUser = { id: 0, email: selectedEmail, clientId: parsedClientId };
          const res: IUser = await apiClient.addUserToClient(token, user);
          await getInitData();
          //setCreateUserIsOpen(false);
          if (typeof res === "undefined") {
            //  setError(true);
          }
        }
      }
    } catch (error) {
      // setError(true);
      console.log("errorsss", error);
    }

  }

  if (client === null) {
    return null;
  }
  return (
    <div style={{backgroundColor: "#F5F5F5" }}>
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center">

        <Grid item xs={11} lg={7}>
          <Paper sx={{ mb: 2, p: 2 }}>
            <Typography variant="h4" gutterBottom component="div">
              {client.name}
            </Typography>

            <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Details" {...a11yProps(0)} onClick={() => handleTabClick(0)} />
              <Tab label="Users" {...a11yProps(1)} onClick={() => handleTabClick(1)} />
              <Tab label="HMIs" {...a11yProps(2)} onClick={() => handleTabClick(2)} />
            </Tabs>

            {tabValue === 0 && <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { marginTop: 3 },
                p: 0,
              }}
              noValidate
              autoComplete="off"
            >
              <Typography
                variant="h6"
                sx={{marginTop: 3}}
              >
                Client details
              </Typography>
              <TextField
                required
                fullWidth={true}
                id="outlined"
                label="Client name"
                value={client.name}
                onChange={(e) => { setClient({ ...client, name: e.target.value }); setClientIsSaved(false);}}
              />

              <TextField
                required
                fullWidth={true}
                id="outlined"
                label="Contact"
                value={client.contact}
                onChange={(e) => { setClient({ ...client, contact: e.target.value }); setClientIsSaved(false);}}
              />

              <DialogActions>

                <Button variant="outlined" onClick={() => navigate("/admin/clients")} disableElevation={true} size="medium" sx={{ textTransform: "none" }}>
                  Cancel
                </Button>

                <Button variant="contained" color="error" onClick={handleDeleteClient} disableElevation={true} size="medium" sx={{ textTransform: "none" }}>
                    Delete client
                  </Button>

                <Button variant="contained" disabled={clientIsSaved} onClick={handleUpdateClient} disableElevation={true} size="medium" sx={{ textTransform: "none" }}>
                  Save
                </Button>
              </DialogActions>

            </Box>}

            {tabValue === 1 && <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { marginTop: 3 },
                p: 0,
                marginTop: 1
              }}
              noValidate
              autoComplete="off"
            >
              {route === "editUser" && userInEdit !== null && <>
                <Typography
                  variant="h6"
                >
                  Edit user: <br />
                  {userInEdit.email}
                </Typography>

                <DialogActions>

                  <Button variant="outlined" onClick={() => setRoute("listUsers")} disableElevation={true} size="medium" sx={{ textTransform: "none" }}>
                    Cancel
                  </Button>

                  <Button variant="contained" color="error" onClick={handleDeleteUser} disableElevation={true} size="medium" sx={{ textTransform: "none" }}>
                    Delete user
                  </Button>

                </DialogActions>
              </>}
              {route === "addUser" && <>

                <Typography
                  variant="h6"
                >
                  Select user to add:
                </Typography>
                <Select options={options} onChange={onSelectChange} />

                <DialogActions>

                  <Button variant="outlined" onClick={() => setRoute("listUsers")} disableElevation={true} size="medium" sx={{ textTransform: "none" }}>
                    Cancel
                  </Button>

                  <Button variant="contained" onClick={handleAddUser} disableElevation={true} size="medium" sx={{ textTransform: "none" }}>
                    Add user
                  </Button>
                </DialogActions>

              </>}
              {route === "listUsers" && <ListUsersTable rows={userRows} onCreateUser={() => setRoute("addUser")} />}
            </Box>}

            {route === "listHMIs" && <ListHMIsTable rows={hmiRows} onCreateHMI={() => handleCreateHMIDialog()} />}

            {(route === "editHmi" || route === "createHmi") && hmiInEdit !== null && <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { marginTop: 3 },
                p: 0
              }}
              noValidate
              autoComplete="off"
            >

              <Typography
                variant="h6"
              >
                Edit HMI information:
              </Typography>
              <TextField
                required
                fullWidth={true}
                id="outlined"
                label="IP-Adress"
                value={hmiInEdit.ip ? hmiInEdit.ip : ""}
                onChange={(e) => { setHmiInEdit({ ...hmiInEdit, ip: e.target.value }); setHmiIsSaved(false);}}
              />

              <TextField
                required
                fullWidth={true}
                id="outlined"
                label="Name"
                value={hmiInEdit.name ? hmiInEdit.name : ""}
                onChange={(e) =>  { setHmiInEdit({ ...hmiInEdit, name: e.target.value }); setHmiIsSaved(false);}}
              />

               <TextField
                required
                fullWidth={true}
                id="outlined"
                label="Location"
                value={hmiInEdit.location ? hmiInEdit.location : ""}
                onChange={(e) => { setHmiInEdit({ ...hmiInEdit, location: e.target.value }); setHmiIsSaved(false);}}
              />

              <TextField
                required
                fullWidth={true}
                id="outlined"
                label="Sys name"
                value={hmiInEdit.sysName ? hmiInEdit.sysName : ""}
                onChange={(e) => { setHmiInEdit({ ...hmiInEdit, sysName: e.target.value }); setHmiIsSaved(false);}}
              />


              <DialogActions>

                <Button variant="outlined" onClick={() => setRoute("listHMIs")} disableElevation={true} size="medium" sx={{ textTransform: "none" }}>
                  Cancel
                </Button>

                <Button variant="contained" color="error" onClick={handleDeleteHMI} disableElevation={true} size="medium" sx={{ textTransform: "none" }}>
                    Delete HMI
                </Button>

                {route === "editHmi" && <Button variant="contained" disabled={hmiIsSaved} onClick={handleUpdateHmi} disableElevation={true} size="medium" sx={{ textTransform: "none" }}>
                  Save
                </Button>}

                {route === "createHmi" && <Button variant="contained" onClick={handleCreateHmi} disableElevation={true} size="medium" sx={{ textTransform: "none" }}>
                  + Create HMI
                </Button>}
              </DialogActions>

            </Box>}
          </Paper>
        </Grid>
      </Grid>
    </Box>
    </div>
  );
};
//export default Clients;
export default withAuthenticationRequired(EditClient, {
  onRedirecting: () => <span>Loading</span>,
});