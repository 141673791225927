import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IHmi } from "../models/Hmi";

interface IProps {
  open: boolean;
  hmi: IHmi | null;
  onClose: () => void;
  onSave: () => void;
  onChangeName: (name: string) => void;
  onChangeLocation: (name: string) => void;
}

const EditHmiDetailsDialog: React.FC<IProps> = ({
  open,
  hmi,
  onClose,
  onSave,
  onChangeName,
  onChangeLocation
}) => {

  const handleChangeName = (e: any) => {
    onChangeName(e.target.value);
  };

  const handleChangeLocation = (e: any) => {
    onChangeLocation(e.target.value);
  };

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    onSave();
  };

  if (hmi === null) {
    return null;
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Change name</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the new name for your HMI:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="name"
            fullWidth
            variant="standard"
            defaultValue={hmi.name}
            onChange={handleChangeName}
          />

          <TextField
            autoFocus
            margin="dense"
            id="location"
            label="Location"
            type="location"
            fullWidth
            variant="standard"
            defaultValue={hmi.location}
            onChange={handleChangeLocation}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditHmiDetailsDialog;
