import "./App.css";
import { useState, useEffect } from "react";
import Navbar from "./components/navbar";
import Profile from "./components/profile";
import ListClients from "./Pages/Admin/ListClients";
import EditClient from "./Pages/Admin/EditClient";
import HMIs from "./Pages/HMIs";
import { useAuth0 } from "@auth0/auth0-react";
import { Routes, Route } from "react-router-dom";
import Start from "./Pages/Start";
import * as apiClient from "./services/apiClient";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const buttonTheme = createTheme({
  palette: {
    primary: {
      main: "#004d64",
    },
    secondary: {
      main: "#004d64",
    },
  },
});

function App() {
  const { logout, user, getAccessTokenSilently } = useAuth0();
  const [userRoles, setUserRoles] = useState<Array<string>>([]);
  const [userNickname, setUserNickname] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleLogout = () => {
    setUserRoles([]);
    logout();
  };

  const handleAuthChange = async () => {
    if (typeof user !== "undefined") {
      if (
        typeof user.nickname !== "undefined" &&
        typeof user.email !== "undefined"
      ) {
        setUserNickname(user.nickname);
        setUserEmail(user.email);
        const token = await getAccessTokenSilently();
        const isAdmin = await apiClient.isAdmin(token);
        if (isAdmin) {
          setUserRoles(["admin"]);
        } else {
          setUserRoles(["user"]);
        }
      }
    }
  };

  useEffect(() => {
    handleAuthChange();
    
  }, [user]);
  return (
    <div className="App">
      <Navbar
        userRoles={userRoles}
        userNickname={userNickname}
        onLogout={handleLogout}
      />
      <ThemeProvider theme={buttonTheme}>
        <Routes>
          <Route path="profile" element={<Profile />} />
          <Route path="hmis" element={<HMIs userEmail={userEmail} />} />
          <Route path="admin/clients" element={<ListClients />} />
          <Route path="admin/client/:clientId" element={<EditClient userEmail={userEmail}/>} />
          <Route
            path="*"
            element={<Start userEmail={userEmail} isLoading={isLoading} />}
          />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
